<template>
  <div>
    <hedaer @setSelect="setItem"></hedaer>
    <div class="home item">
      <div class="wow fadeInUp" data-wow-delay="0.3s">
        <ul class="home-tab">
          <li :class="{ selectBab: selectBab == 1 }" @click="selectBab = 1">
            企业简介
          </li>
          <li :class="{ selectBab: selectBab == 2 }" @click="selectBab = 2">
            战略布局
          </li>
          <li :class="{ selectBab: selectBab == 3 }" @click="selectBab = 3">
            旗下品牌
          </li>
        </ul>
        <div class="home-line"></div>
      </div>
      <div class="CompanyProfile" v-show="selectBab == 1">
        <div class="Profile-title wow fadeInUp" data-wow-delay="0.4s">
          企业简介
        </div>
        <div class="Profile-en wow fadeInUp" data-wow-delay="0.5s">
          BRAND INTRODUCTION
        </div>
        <div class="Profile-text wow fadeInUp" data-wow-delay="0.6s">
          碧莲盛成立于2005年，是一家以植发技术的自主研发和临床应用为核心，致力于毛发健康产业发展的全国直营连锁机构。18年来精钻植发技术，连续13年参加世界植发大会，向世界展示中国植发技术。
        </div>
        <div class="Profile-text wow fadeInUp">
          在华盖资本5亿融资加持下，碧莲盛全力推动品牌战略升级，通过成立技术研发中心、重塑服务体系、扩展旗舰医院、研发自有品牌护发产品等，不断深化行业布局，加强上下游资源整合，线上线下链路打通，构建一个集养发、护发、健发、植发于一体的毛发健康产业生态闭环。
        </div>
        <div class="Profile-text wow fadeInUp">
          目前，碧莲盛在全国开设了40余家直营机构，拥有100余位执业医生，700余位执业护士，其中主任/副主任医师10余位，为35+万发友解决了脱发困扰。
        </div>
        <img src="../assets/home/1.jpg" alt="" class="Profile-img wow fadeInUp" style="margin-top: 0.5556rem" />
        <img src="../assets/home/2.jpg" alt="" class="Profile-img wow fadeInUp" />
      </div>
      <div class="StrategicLayout" v-show="selectBab == 2">
        <div class="Profile-title wow fadeInUp">战略布局</div>
        <div class="Profile-en wow fadeInUp">BRAND BRAND LAYOUT</div>
        <div class="Profile-text wow fadeInUp">
          碧莲盛是一家辐射全国的大型连锁植发机构，自成立以来，致力于为全国发友带来无差异的、高标准的植发服务。凭借先进的植发技术、安全贴心的医疗服务、深厚的用户口碑，碧莲盛先后在全国开设了40余家直营机构，不断推进实体旗舰医院的战略布局。
        </div>
        <div class="Profile-text wow fadeInUp">
          目前，碧莲盛建立了统一规范的品牌体系、运营体系、培训体系、就诊流程和服务标准，全国院部保持统一的视觉形象输出，同时对前台、顾问、医护团队进行统一的培训，全国所有家分院遵循统一的管理标准，以确保全国用户都能享受到品质如一的服务。
        </div>
        <div class="map wow fadeInUp">
        </div>

        <!-- <div class="map wow fadeInUp">
          <div class="box" style="top: 2.4074rem; left: 1.8rem"></div>
          <div class="box" style="top: 5.2rem; left: 3.8rem"></div>
          <div class="box" style="top: 0.7rem; left: 7.6815rem"></div>
          <div class="box" style="top: 1.3rem; left: 7.1593rem"></div>
          <div class="box" style="top: 0.7rem; left: 7.7074rem"></div>
          <div class="box" style="top: 0.8rem; left: 7rem"></div>
          <div class="box" style="top: 1.8rem; left: 6rem"></div>
          <div class="box" style="top: 1rem; left: 5.6rem"></div>
          <div class="box" style="top: -0rem; left: 5.8rem"></div>
          <div class="box" style="top: 1rem; left: 5.2rem"></div>
          <div class="box" style="top: 1rem; left: 6rem"></div>
          <div class="box" style="top: 1.1rem; left: 6.1rem"></div>
          <div class="box" style="top: 0.6rem; left: 4.55rem"></div>
          <div class="box" style="top: 0.1rem; left: 5.1852rem"></div>
          <div class="box" style="top: -0.5rem; left: 4rem"></div>

          <div class="box" style="top: 0.7rem; left: 5.1rem"></div>
          <div class="box" style="top: -2.5rem; left: 6.2rem"></div>
          <div class="box" style="top: -0.6rem; left: 5.6rem"></div>
          <div class="box" style="top: -2rem; left: 6.7rem"></div>
          <div class="box" style="top: -3.8rem; left: 5rem"></div>
          <div class="box" style="top: -1.7rem; left: 4.85rem"></div>
          <div class="box" style="top: -4.5rem; left: 3.8rem"></div>
          <div class="box" style="top: -5.5rem; left: 6.1rem"></div>
          <div class="box" style="top: -3rem; left: 6.75rem"></div>
          <div class="box" style="top: -4.9rem; left: 4.05rem"></div>
          <div class="box" style="top: -6.3rem; left: 4.3rem"></div>
          <div class="box" style="top: -7rem; left: 4.9rem"></div>
        </div> -->
        <div class="wow fadeInUp">
          <div style="
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              padding: 0 1.7rem;
              margin-bottom: 0.5rem;
            ">
            <div class="StrategicLayout-item" id="StrategicLayoutAdd">
              <div>
                <span>
                  <countTo ref="countTo1" :startVal="0" :endVal="18" :duration="1000" :autoplay="false" :decimals="0" :useEasing="true"></countTo>
                </span>
                年
              </div>
              <div>专研植发</div>
            </div>
            <div class="StrategicLayout-item">
              <div style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                <span style="margin-right: 0.0867rem">
                  <countTo ref="countTo2" :startVal="0" :endVal="40" :duration="1000" :autoplay="false" :decimals="0" :useEasing="true"></countTo>
                </span>

                <div style="margin-bottom: 0.1rem">
                  <div style="font-size: 0.5rem">+</div>
                  <div>家</div>
                </div>
              </div>

              <div>直营机构</div>
            </div>
          </div>
          <div style="
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
            ">
            <div class="StrategicLayout-item">
              <div style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                <span style="margin-right: 0.0867rem">
                  <countTo ref="countTo3" :startVal="0" :endVal="100" :duration="1000" :autoplay="false" :decimals="0" :useEasing="true"></countTo>
                </span>

                <div style="margin-bottom: 0.1rem">
                  <div style="font-size: 0.5rem">+</div>
                  <div>位</div>
                </div>
              </div>

              <div>执业医师</div>
            </div>
            <div class="StrategicLayout-item">
              <div style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                <span style="margin-right: 0.0867rem">
                  <countTo ref="countTo4" :startVal="0" :endVal="700" :duration="1000" :autoplay="false" :decimals="0" :useEasing="true"></countTo>
                </span>

                <div style="margin-bottom: 0.1rem">
                  <div style="font-size: 0.5rem">+</div>
                  <div>位</div>
                </div>
              </div>

              <div>执业护士</div>
            </div>
            <div class="StrategicLayout-item">
              <div style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                <span style="
                    margin-right: 0.0867rem;
                    display: flex;
                    align-items: center;
                  ">
                  <countTo ref="countTo5" :startVal="0" :endVal="35" :duration="1000" :autoplay="false" :decimals="0" :useEasing="true"></countTo><span style="font-size: 0.7rem">万</span>
                </span>

                <div style="margin-bottom: 0.1rem">
                  <div style="font-size: 0.5rem">+</div>
                  <div>台</div>
                </div>
              </div>

              <div>植发手术</div>
            </div>
          </div>
        </div>
      </div>
      <div class="Brands" v-show="selectBab == 3">
        <div class="Profile-title wow fadeInUp">旗下品牌</div>
        <div class="Profile-en wow fadeInUp">OWN BRANDS</div>

        <div class="Brands-item wow fadeInUp">
          <img src="https://static.drlianzhuren.com/brandWeb//home/ItsBrands1.png" alt="" class="Brands-img" />
          <div class="UnderstandBrand">了解品牌</div>
        </div>

        <div class="Brands-itemT wow fadeInUp" data-wow-delay="0.5s">
          <img src="https://static.drlianzhuren.com/brandWeb//home/yfLOGO.png" class="Brands-imgT" alt="" />
          <div class="UnderstandBrand">了解品牌</div>
        </div>
      </div>
    </div>
    <Footer @setItem="setItem"></Footer>
  </div>
</template>

<script>
import { observerBox } from '../utils/Intersection'
import countTo from 'vue-count-to'
export default {
  components: {
    countTo
  },
  data () {
    return {
      selectBab: 1
    }
  },
  methods: {
    setItem (a) {
      this.selectBab = a
    }
  },

  mounted () {
    if (this.$route.params.id) {
      this.selectBab = this.$route.params.id + 1
    }
    const that = this
    const dom = document.querySelector('#StrategicLayoutAdd')
    observerBox(dom, (target) => {
      that.$refs.countTo1.start()
      that.$refs.countTo2.start()
      that.$refs.countTo3.start()
      that.$refs.countTo4.start()
      that.$refs.countTo5.start()
    })
  }
}
</script>

  <style lang="scss">
.home {
  .CompanyProfile {
    .Profile-img {
      width: 100%;
      border-radius: 8px;
      margin-top: 25px;
    }
  }

  .map {
    margin: 24px 0;
    width: 500px;
    position: relative;
    // background: url("../assets/home/map.png");
    background: url('https://static.drlianzhuren.com/HairTransplant/home/strategicLayout.jpg?v=2');
    background-size: 100% 100%;
    // height: 378px;
    height: 300px;
    // margin-bottom: 50px;
    .box {
      position: relative;

      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .box::before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      background: #76b8ff;

      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin-left: -10px;
      margin-top: -10px;
      opacity: 0;
      animation: animate 1.6s infinite linear;
      animation-delay: -0.8s !important;
    }

    .box::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      background: #76b8ff;

      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin-left: -10px;
      margin-top: -10px;
      opacity: 0;
      animation: animate 1.6s infinite linear;
    }

    @keyframes animate {
      0% {
        background: #18b6ff;
        transform: scale(0);
        opacity: 1;
      }

      50% {
        transform: scale(0.3);
        background: rgb(133, 206, 252);
        // opacity: 1;
      }

      100% {
        transform: scale(1);
        background: #ffffff;
        // opacity: 0;
      }
    }
  }
  .StrategicLayout-item {
    width: 140px;
    height: 140px;
    border-radius: 18px;
    border: solid 1px #2e59a7;
    text-align: center;
    span {
      font-size: 46px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 100px;
      letter-spacing: -2px;
      color: #2e59a7;
    }
    > div {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0px;
      letter-spacing: 1px;
      color: #2e59a7;
    }
  }
  .Brands-item {
    width: 500px;
    height: 325px;
    background: url('../assets/home/3.jpg') no-repeat;
    background-size: 100% 100%;
    margin-bottom: 20px;
    border-radius: 8px;

    text-align: center;
  }
  .Brands-itemT {
    width: 500px;
    height: 325px;
    background: url('../assets/home/4.jpg') no-repeat;
    background-size: 100% 100%;
    margin-bottom: 20px;
    border-radius: 8px;

    text-align: center;
  }
  .Brands-img {
    width: 313px;
    height: 52px;
    margin-top: 110px;
  }
  .Brands-imgT {
    width: 280px;
    margin-top: 90px;
  }
  .UnderstandBrand {
    text-align: center;
    width: 159px;
    height: 38px;
    line-height: 38px;
    font-size: 20px;
    color: #363636;
    background-color: #ffffff;
    border-radius: 19px;
    margin: 55px auto 0;
  }
}
</style>
