import IntersectionObserver from 'intersection-observer-polyfill'

let callback = null

IntersectionObserver.prototype.POLL_INTERVAL = 50
const observer = new IntersectionObserver((entries) => {
  entries.forEach((el) => {
    if (el.intersectionRatio > 0) {
      if (callback) callback(el.target)
      observer.unobserve(el.target)
    }
  })
}, {
  rootMargin: '0px -100px 0px 0px',
  threshold: 0.1 // 元素出现的阈值
})
// observer.POLL_INTERVAL = 50

export const observerBox = function (target, fn) {
  observer.observe(target)
  callback = fn
}
